.main {
  height: 100vh;
  overflow-y: scroll;
  background-color: #000;

  .main__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px;

    .bottom__form {
      margin-bottom: 50px;
      padding: 50px 50px 30px;
      width: 50vw;
      border-radius: 10px;
      background-color: #FFF;

      .form__top {
        border-bottom: 1px solid #D3D6DB;

        > p {
          margin: 12px 0 24px;
          line-height: 32px;
        }
      }

      .form__bottom {
        padding-top: 30px;

        > p {
          margin-top: 15px;
        }

        .main__category {
          margin-top: 30px;
        }

        .main__field {
          display: flex;
          margin: 20px 0 20px 17px;
          gap: 16px;

          & > p {
            line-height: 20px;
          }
        }
      }
    }
  }

  .main__logo {
    img {
      width: 169px;
      filter: invert(1);
    }
  }
}
